import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  numberOfItems: {
    id: 'plural.numberOfItems',
    defaultMessage: '{count} {count, plural, one {item} other {items}}',
    description: 'Plural label for number of items',
  },
  pay: {
    id: 'actions.pay',
    defaultMessage: 'Pay',
    description: 'Common label for the pay action',
  },
  subtotal: {
    id: 'common.subtotal',
    defaultMessage: 'Subtotal',
    description: 'Common label for subtotal',
  },
  vat: {
    id: 'common.vat',
    defaultMessage: 'VAT',
    description: 'Common label for vat',
  },
  vatShifted: {
    id: 'common.vatShifted',
    defaultMessage: 'VAT shifted',
    description: 'Common label for vat shifted',
  },
});
