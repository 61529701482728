import { useIntl } from 'react-intl';
import { Box, Label } from '@afosto/components';
import { CardSelector } from '../../../CardSelector';
import { Currency } from '../../../Currency';
import { Fieldset } from '../../../Fieldset';
import { translations } from './translations';
import type { PaymentsFormSectionProps } from './types';

export const PaymentsFormSection = (props: PaymentsFormSectionProps) => {
  const { paymentMethod, PaymentMethodIcon, payments = [] } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.payment)}>
      {(payments || []).map((payment) => (
        <CardSelector
          key={payment.id}
          Icon={PaymentMethodIcon}
          label={paymentMethod?.name}
          description={
            <Currency currency={payment.currency} value={payment.amountPaid} />
          }
        >
          {payment.isPaid && (
            <Box
              sx={{
                alignSelf: 'flex-start',
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <Label
                color="success"
                label={intl.formatMessage(translations.paid)}
                size="large"
              />
            </Box>
          )}
        </CardSelector>
      ))}
    </Fieldset>
  );
};
