import { gql } from '@afosto/graphql-client';

export const CoreOrderFragment = gql`
  fragment CoreOrderFragment on Order {
    id
    number
    is_vat_shifted
    adjustments {
      id
      amount
      description
      is_percentage
      is_discount
      outcome {
        amount
        rule_id
      }
    }
    coupons {
      id
      code
    }
    currency
    customer {
      contact {
        id
        email
        given_name
        additional_name
        family_name
        phone_numbers {
          primary {
            id
            country_code
            number
          }
        }
      }
      organisation {
        id
        name
        administration {
          email
        }
        phone_numbers {
          primary {
            id
            country_code
            number
          }
        }
      }
      notes
    }
    fees {
      shipping {
        description
        total
      }
      payment {
        description
        total
      }
    }
    items {
      ids
      image
      label
      quantity
      sku
      subtotal
      total
      adjustments {
        id
        amount
        description
        is_percentage
        is_discount
        outcome {
          amount
          rule_id
        }
      }
      details {
        meta_data
        serial_number
        pricing {
          amount
        }
      }
    }
    subtotal
    total
    vat {
      amount
      rate
    }
  }
`;
