import { useIntl } from 'react-intl';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ShortcutCard } from '../ShortcutCard';
import { User } from '../../icons/solid';
import { getFullName } from '../../utils';
import { translations } from './translations';

export const CustomerShortcut = () => {
  const intl = useIntl();
  const {
    openAddCustomerDialog,
    openManageContactDialog,
    openManageOrganisationDialog,
    order,
  } = useOrder();
  const { contact, organisation } = order?.customer || {};
  const hasCustomer = !!contact?.id || !!organisation?.id;
  let label = intl.formatMessage(translations.addCustomer);

  if (contact?.id) {
    label = getFullName(contact);
  }

  if (organisation?.id) {
    label = organisation?.name;
  }

  const handleClick = () => {
    if (hasCustomer) {
      if (contact) {
        openManageContactDialog(contact);
      } else if (organisation) {
        openManageOrganisationDialog(organisation);
      }
    } else {
      openAddCustomerDialog();
    }
  };

  return (
    <ShortcutCard
      color={hasCustomer ? 'success' : undefined}
      label={label}
      Icon={User}
      onClick={handleClick}
    />
  );
};
