import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Fields, Grid } from '@afosto/components';
import { FormDialog } from '../FormDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { validationSchema } from './validationSchema';
import { translations } from './translations';
import type { OrderNoteFormData, OrderNoteFormDialogProps } from './types';

export const OrderNoteFormDialog = (props: OrderNoteFormDialogProps) => {
  const { formProps, title, ...otherProps } = props;

  const intl = useIntl();
  const { closeOrderNoteDialog, setNoteForOrder, order } = useOrder();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentOrderNote = order?.customer?.notes;
  const titleKey = (
    currentOrderNote ? 'editNote' : 'addNote'
  ) as keyof typeof translations;

  const defaultValues = {
    note: currentOrderNote || '',
  };

  const handleSubmit = async (data: OrderNoteFormData) => {
    try {
      setIsSubmitting(true);

      await setNoteForOrder({
        note: data.note || '',
      });

      closeOrderNoteDialog();
      setIsSubmitting(false);
    } catch (error) {
      // TODO: Do something with error.
      setIsSubmitting(false);
      return Promise.reject(error);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      formProps={{
        ...(formProps || {}),
        defaultValues,
        isSubmitting,
        submitLabel: intl.formatMessage(
          translations[currentOrderNote ? 'save' : 'add']
        ),
        validationSchema,
      }}
      onSubmit={handleSubmit}
      title={title || intl.formatMessage(translations[titleKey])}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Fields.TextField
            name="note"
            label={intl.formatMessage(translations.note)}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};
