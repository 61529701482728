import { Box, Typography } from '@afosto/components';
import type { FieldsetProps } from './types';

export const Fieldset = (props: FieldsetProps) => {
  const { children, title } = props;

  return (
    <Box
      component="fieldset"
      sx={{
        border: 0,
        p: 0,
        ...(title
          ? {
              '&:first-of-type': {
                mt: -2,
              },
            }
          : {}),
        '& + &': {
          mt: 2,
        },
      }}
    >
      {title && (
        <Typography variant="h4" my={3}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
