import { ReceiptItem } from '../ReceiptItem';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import * as Styled from './ReceiptItems.styles';
import type { OrderItem } from '../../types';

export const ReceiptItems = () => {
  const { openManageOrderItemDialog, order } = useOrder();
  const { items = [] } = order || {};

  const handleManageItem = (item: OrderItem) => () => {
    openManageOrderItemDialog(item);
  };

  return (
    <Styled.Container>
      {items.map((item) => {
        const [id] = item.ids || [];

        return (
          <ReceiptItem
            key={id}
            currency={order?.currency}
            item={item}
            onClick={handleManageItem(item)}
          />
        );
      })}
    </Styled.Container>
  );
};
