import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  description: {
    id: 'components.selectPaymentMethodDialog.description',
    defaultMessage: 'Select a payment method for checkout',
    description: 'Description of the select payment method dialog',
  },
  back: {
    id: 'actions.back',
    defaultMessage: 'Back',
    description: 'Common label for the back action',
  },
  close: {
    id: 'actions.close',
    defaultMessage: 'Close',
    description: 'Common label for the close action',
  },
  paidWith: {
    id: 'common.paidWith',
    defaultMessage: 'Paid with',
    description: 'Common label for paid with',
  },
  paymentAccepted: {
    id: 'feedback.paymentAccepted.message',
    defaultMessage: 'Payment accepted',
    description: 'Common feedback message for payment accepted',
  },
  processingPayment: {
    id: 'feedback.processingPayment.message',
    defaultMessage: 'Processing payment',
    description: 'Common feedback message for processing payment',
  },
  retry: {
    id: 'actions.retry',
    defaultMessage: 'Retry',
    description: 'Common label for the retry action',
  },
});
