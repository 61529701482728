import { ReceiptCustomer } from '../ReceiptCustomer';
import { ReceiptItems } from '../ReceiptItems';
import { ReceiptSummary } from '../ReceiptSummary';
import * as Styled from './Receipt.styles';

export const Receipt = () => {
  return (
    <Styled.Container>
      <Styled.ReceiptContent>
        <ReceiptCustomer />
        <ReceiptItems />
      </Styled.ReceiptContent>
      <ReceiptSummary />
    </Styled.Container>
  );
};
