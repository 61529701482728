import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import apiClient from '@afosto/api-client';
import { Box, IconStatusWrapper, Link, Typography } from '@afosto/components';
import { useQuery } from '@tanstack/react-query';
import { Currency } from '../Currency';
import { FullscreenDialog } from '../FullscreenDialog';
import { Loader } from '../Loader';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { PaymentMethodsList } from '../PaymentMethodsList';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { getOrderPaymentMethods, type OrderPayment } from '../../queries';
import { PaymentAcceptedFormSection } from './components/PaymentAcceptedFormSection';
import { PaymentFailedFormSection } from './components/PaymentFailedFormSection';
import { CheckCircle } from '../../icons/solid';
import { PAYMENT_METHOD_ICON_MAPPING } from '../../constants/iconMappings';
import { getErrorMessage } from '../../utils';
import { translations } from './translations';
import type { CheckoutDialogProps } from './types';
import type { PaymentMethod } from '../../types';

export const CheckoutDialog = (props: CheckoutDialogProps) => {
  const { open = false, onClose, ...otherProps } = props;

  const intl = useIntl();
  const {
    addPaymentMethodToOrder,
    createOrderPayment,
    getOrderPayment,
    markPaymentAsPaid,
    openCashDrawer,
    order,
    printOrder,
    setOrder,
  } = useOrder();
  const { printingEnabled } = usePrint();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [processedPayment, setProcessedPayment] = useState<OrderPayment | null>(
    null
  );
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const paymentMethodCode = paymentMethod?.code?.toLowerCase();
  const PaymentMethodIcon =
    PAYMENT_METHOD_ICON_MAPPING[
      paymentMethodCode as keyof typeof PAYMENT_METHOD_ICON_MAPPING
    ];

  const { data: paymentMethods, isFetched } = useQuery({
    ...getOrderPaymentMethods(order?.id || ''),
    enabled: !!order?.id && open,
  });

  const handleBackToPaymentMethods = () => {
    setPaymentErrorMessage('');
    setPaymentFailed(false);
    setPaymentMethod(null);
    setProcessedPayment(null);
  };

  const handleStartPayment = async (selectedPaymentMethod: PaymentMethod) => {
    try {
      if (!order) {
        throw new Error('Order not found');
      }

      setIsProcessingPayment(true);
      setPaymentErrorMessage('');
      setPaymentCompleted(false);
      setPaymentFailed(false);
      setPaymentMethod(selectedPaymentMethod);
      setProcessedPayment(null);

      const [firstIssuer] = selectedPaymentMethod?.issuers || [];

      const response = await addPaymentMethodToOrder({
        methodId: selectedPaymentMethod.id,
        ...(firstIssuer ? { issuerId: firstIssuer.id } : {}),
      });

      let createdPayment: OrderPayment | null = null;

      if (!selectedPaymentMethod.isManual) {
        const { proceedingStep } =
          response?.addPaymentMethodToOrder?.order?.options || {};
        const { options } = proceedingStep?.proceeding || {};
        const [firstOption] = options || [];

        if (!firstOption?.url) {
          throw new Error('No payment option available');
        }

        const paymentResponse = await apiClient.request({
          url: firstOption.url,
          method: 'get',
        });
        createdPayment = paymentResponse?.data?.data || null;
      } else {
        const response = await createOrderPayment({
          payment: {
            amount: order.total,
          },
        });
        const paymentId = response?.data?.id;

        if (!paymentId) {
          throw new Error('Something went wrong while creating the payment');
        }

        await markPaymentAsPaid({
          payment: {
            id: paymentId,
            amount: order.total,
          },
        });
        createdPayment = await getOrderPayment(paymentId);
      }

      setProcessedPayment(createdPayment);

      if (createdPayment?.isPaid) {
        setPaymentCompleted(true);
      } else {
        setPaymentFailed(true);
      }

      if (
        printingEnabled &&
        selectedPaymentMethod?.code?.toLowerCase() === 'cash'
      ) {
        openCashDrawer().catch(() => {
          // Do nothing.
        });
      }

      setIsProcessingPayment(false);
    } catch (error) {
      const message = getErrorMessage(error as Error);

      setPaymentErrorMessage(message);
      setPaymentFailed(true);
      setIsProcessingPayment(false);
    }
  };

  const handlePrintOrder = async () => {
    try {
      await printOrder({ payment: processedPayment, paymentMethod });
    } catch {
      //TODO: Do something with error.
    }
  };

  const handleRetryPayment = () => {
    if (paymentMethod) {
      handleStartPayment(paymentMethod).catch(() => {
        // Do nothing.
      });
    }
  };

  const handleClose = () => {
    if (paymentCompleted) {
      setOrder(null);
    }

    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleExited = () => {
    setIsProcessingPayment(false);
    setPaymentCompleted(false);
    setPaymentErrorMessage('');
    setPaymentFailed(false);
    setProcessedPayment(null);
  };

  return (
    <FullscreenDialog
      {...otherProps}
      description={intl.formatMessage(translations.description)}
      dialogHeaderProps={
        paymentCompleted
          ? {
              title: intl.formatMessage(translations.paymentAccepted),
              description: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    color="gray.900"
                    component="span"
                    fontWeight={500}
                    variant="bodyLarge"
                  >
                    <Currency
                      currency={processedPayment?.currency}
                      value={processedPayment?.amountPaid}
                    />
                  </Typography>
                  <Typography
                    color="gray.800"
                    component="span"
                    variant="bodyLarge"
                  >
                    {intl.formatMessage(translations.paidWith).toLowerCase()}:
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    {PaymentMethodIcon && (
                      <PaymentMethodIcon sx={{ fontSize: 24 }} />
                    )}
                    <Typography
                      color="gray.800"
                      component="span"
                      variant="bodyLarge"
                    >
                      {paymentMethod?.name}
                    </Typography>
                  </Box>
                </Box>
              ),
              startAdornment: (
                <IconStatusWrapper
                  color="success"
                  IconComponent={CheckCircle}
                  size="medium"
                />
              ),
            }
          : {}
      }
      hideDialogHeader={isProcessingPayment || paymentFailed}
      onClose={!isProcessingPayment ? handleClose : undefined}
      open={open}
      title={<Currency currency={order?.currency} value={order?.total} />}
      topBarProps={{
        actions: !isProcessingPayment ? (
          <>
            {!paymentCompleted && !paymentFailed && (
              <Link
                color="gray.900"
                component="button"
                onClick={handleClose}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.back} />
              </Link>
            )}
            {paymentCompleted && (
              <Link
                component="button"
                onClick={handleClose}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.close} />
              </Link>
            )}
            {paymentFailed && !processedPayment?.isCancelled && (
              <Link
                component="button"
                onClick={handleRetryPayment}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.retry} />
              </Link>
            )}
          </>
        ) : undefined,
      }}
      TransitionProps={{ onExited: handleExited }}
    >
      {isProcessingPayment && (
        <Loader
          message={`${intl.formatMessage(translations.processingPayment)}...`}
          sx={{
            mt: 6,
          }}
        />
      )}
      {!isProcessingPayment &&
        !paymentCompleted &&
        !paymentFailed &&
        isFetched && (
          <PaymentMethodsList
            onSelectPaymentMethod={handleStartPayment}
            paymentMethods={paymentMethods || []}
          />
        )}
      {!isProcessingPayment && paymentCompleted && (
        <PaymentAcceptedFormSection
          onPrint={handlePrintOrder}
          printingEnabled={printingEnabled}
          payments={processedPayment ? [processedPayment] : []}
          paymentMethod={paymentMethod}
          PaymentMethodIcon={PaymentMethodIcon}
        />
      )}
      {!isProcessingPayment && paymentFailed && (
        <PaymentFailedFormSection
          errorMessage={paymentErrorMessage}
          onBack={handleBackToPaymentMethods}
          onRetry={handleRetryPayment}
          payment={processedPayment}
        />
      )}
    </FullscreenDialog>
  );
};
