import { AppBar, styled } from '@afosto/components';

export const CloseButton = styled('div')`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  padding: ${(props) => props.theme.spacing(1.5)};
`;

export const ActionsContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  margin-left: auto;
  padding-left: ${(props) => props.theme.spacing(3)};
  padding-right: ${(props) => props.theme.spacing(3)};
`;

export const TopBar = styled(AppBar)`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;
