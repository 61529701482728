import { FormattedMessage } from 'react-intl';
import {
  Avatar,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@afosto/components';
import { translations } from './translations';
import type { SearchResultItem, SearchResultsProps } from './types';
import { AngleRight } from '../../icons/regular';

export const SearchResults = <T extends SearchResultItem>(
  props: SearchResultsProps<T>
) => {
  const { items, onSelect, showSelector } = props;

  if (!items.length) {
    return (
      <Typography variant="bodyLarge" mt={4} sx={{ alignSelf: 'center' }}>
        <FormattedMessage {...translations.noResultsFound} />
      </Typography>
    );
  }

  return (
    <List sx={{ overflow: 'hidden' }}>
      {(items || []).map((item) => (
        <ListItemButton
          key={item.id}
          onClick={onSelect(item)}
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
            px: 0,
            py: 2,
          })}
        >
          {'avatar' in item && (
            <ListItemAvatar>
              <Avatar src={item.avatar} variant="rounded" />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{ fontWeight: 500, variant: 'h6' }}
            secondary={item.description}
            secondaryTypographyProps={{
              color: (theme) => theme.palette.gray[800],
              variant: 'bodyMedium',
            }}
          />
          {showSelector && (
            <AngleRight
              sx={{
                color: (theme) => theme.palette.gray[600],
                fontSize: 24,
                alignSelf: 'center',
              }}
            />
          )}
        </ListItemButton>
      ))}
    </List>
  );
};
