import { AuthenticationCallback } from '@afosto/auth-react';
import { App } from './App';
import { AuthLayout } from './components/AuthLayout';
import { Layout } from './components/Layout';
import { AuthenticationError } from './pages/AuthenticationError';
import { Forgot } from './pages/Forgot';
import { SignIn } from './pages/SignIn';
import { Start } from './pages/Start';
import { TwoFactor } from './pages/TwoFactor';

export const routes = [
  {
    element: <App />,
    children: [
      {
        path: 'auth/callback',
        element: <AuthenticationCallback />,
      },
      {
        element: <AuthLayout />,
        path: 'auth',
        children: [
          {
            path: '2fa',
            element: <TwoFactor />,
          },
          {
            path: 'error',
            element: <AuthenticationError />,
          },
          {
            path: 'forgot',
            element: <Forgot />,
          },
          {
            path: 'sign-in',
            element: <SignIn />,
          },
        ],
      },
      {
        element: <Layout />,
        path: '/',
        children: [
          {
            index: true,
            element: <Start />,
          },
        ],
      },
    ],
  },
];
