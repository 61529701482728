import { CheckoutActionsFormSection } from '../CheckoutActionsFormSection';
import { CustomerFormSection } from '../CustomerFormSection';
import { NoteFormSection } from '../NoteFormSection';
import { PaymentsFormSection } from '../PaymentsFormSection';
import type { PaymentAcceptedFormSectionProps } from './types';

export const PaymentAcceptedFormSection = (
  props: PaymentAcceptedFormSectionProps
) => {
  const {
    onPrint,
    payments,
    paymentMethod,
    PaymentMethodIcon,
    printingEnabled,
  } = props;

  return (
    <>
      <CheckoutActionsFormSection
        onPrint={onPrint}
        printingEnabled={printingEnabled}
      />
      <CustomerFormSection />
      <PaymentsFormSection
        paymentMethod={paymentMethod}
        PaymentMethodIcon={PaymentMethodIcon}
        payments={payments}
      />
      <NoteFormSection />
    </>
  );
};
