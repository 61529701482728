import { CategoryShortcut } from '../CategoryShortcut';
import { CustomerShortcut } from '../CustomerShortcut';
import { ManuallyAddProductShortcut } from '../ManuallyAddProductShortcut';
import { OrderDiscountShortcut } from '../OrderDiscountShortcut';
import * as Styled from './Shortcuts.styles';

export const Shortcuts = () => {
  return (
    <Styled.Container>
      <CustomerShortcut />
      <ManuallyAddProductShortcut />
      <OrderDiscountShortcut />
      <CategoryShortcut />
    </Styled.Container>
  );
};
