import { Container, Dialog, DialogHeader, Divider } from '@afosto/components';
import { DialogTopBar } from '../DialogTopBar';
import type { FullscreenDialogProps } from './types';

export const FullscreenDialog = (props: FullscreenDialogProps) => {
  const {
    children,
    description,
    dialogHeaderProps = {},
    hideDialogHeader = false,
    onClose,
    open,
    title,
    topBarProps,
    ...otherProps
  } = props;

  return (
    <Dialog {...otherProps} open={open} fullScreen>
      <DialogTopBar {...topBarProps} onClose={onClose} />
      <Container fixed maxWidth="md">
        {!hideDialogHeader && (
          <>
            <DialogHeader
              title={title}
              description={description}
              {...dialogHeaderProps}
              descriptionTypographyProps={{ variant: 'bodyLarge' }}
              titleTypographyProps={{ variant: 'h2' }}
              sx={{
                px: 0,
                pt: 4.5,
                ...(!description
                  ? { borderBottom: '0!important', pb: '0px!important' }
                  : {}),
              }}
            />
            <Divider sx={{ mb: 3, mt: 2.5 }} />
          </>
        )}
        {children}
      </Container>
    </Dialog>
  );
};
