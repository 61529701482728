import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOrderItemDiscount } from './useOrderItemDiscount';
import {
  addItemsToOrder as addItemsToOrderMutationRequest,
  type AddItemsToOrderInput,
  type CreateOrderInput,
  removeItemsFromOrder as removeItemsFromOrderMutationRequest,
} from '../../../mutations';
import { beep } from '../../../utils';
import { getProductByGtin as getProductByGtinOptions } from '../../../queries';
import type { Category } from '../../CategoryProvider';
import type { Order, OrderItem } from '../../../types';

export interface UseOrderItemsOptions {
  category?: Category | null;
  createOrder: (input?: Omit<CreateOrderInput, 'channelId'>) => Promise<Order>;
  loadOrder: (id: string) => Promise<Order | null>;
  order: Order | null;
  setOrder: (order: Order) => void;
}

export interface ChangeItemQuantityOnOrderInput {
  item: OrderItem;
  quantity: number;
}

export const useOrderItems = (options: UseOrderItemsOptions) => {
  const { category, createOrder, loadOrder, order, setOrder } = options || {};

  const queryClient = useQueryClient();

  const [selectedOrderItem, setSelectedOrderItem] = useState<OrderItem | null>(
    null
  );
  const [showManageOrderItemDialog, setShowManageOrderItemDialog] =
    useState(false);
  const [showManuallyAddProductDialog, setShowManuallyAddProductDialog] =
    useState(false);

  const itemDiscount = useOrderItemDiscount({
    loadOrder,
    order,
    selectedOrderItem,
    setSelectedOrderItem,
  });

  const { mutateAsync: addItemsToOrderMutation } = useMutation({
    mutationFn: addItemsToOrderMutationRequest,
  });

  const { mutateAsync: removeItemsFromOrderMutation } = useMutation({
    mutationFn: removeItemsFromOrderMutationRequest,
  });

  const closeManageOrderItemDialog = useCallback(() => {
    setShowManageOrderItemDialog(false);
  }, []);

  const closeManuallyAddProductDialog = useCallback(() => {
    setShowManuallyAddProductDialog(false);
  }, []);

  const exitedManageOrderItemDialog = useCallback(() => {
    setSelectedOrderItem(null);
  }, []);

  const openManageOrderItemDialog = useCallback((item: OrderItem) => {
    setSelectedOrderItem(item);
    setShowManageOrderItemDialog(true);
  }, []);

  const openManuallyAddProductDialog = useCallback(() => {
    setShowManuallyAddProductDialog(true);
  }, []);

  const getProductByGtin = useCallback(
    async (gtin: string) => {
      const products = await queryClient.fetchQuery({
        ...getProductByGtinOptions(gtin),
        staleTime: 300000,
      });

      // TODO: handle multiple results.
      return (products || []).find((product) => product.sku);
    },
    [queryClient]
  );

  const addItemsToOrder = useCallback(
    async (
      input: Omit<AddItemsToOrderInput, 'orderId'> & { orderId?: string }
    ) => {
      const { items, orderId, requestOptions } = input || {};
      let currentOrderId = orderId || order?.id;

      if (!orderId && !order) {
        const createdOrder = await createOrder();
        currentOrderId = createdOrder.id;
      }

      if (!currentOrderId) {
        throw new Error('Order not found!');
      }

      let itemsInput = items;

      if (category) {
        itemsInput = itemsInput.map((itemInput) => ({
          ...itemInput,
          metaData: {
            ...(itemInput.metaData || {}),
            category: category.key,
          },
        }));
      }

      const response = await addItemsToOrderMutation({
        orderId: currentOrderId,
        items: itemsInput,
        requestOptions,
      });
      const updatedOrder = response?.addItemsToOrder?.order || null;

      setOrder(updatedOrder);
      return updatedOrder;
    },
    [addItemsToOrderMutation, category, createOrder, order, setOrder]
  );

  const addItemToOrderByGtin = useCallback(
    async (gtin: string, item = {}) => {
      const product = await getProductByGtin(gtin);

      if (!product || !product?.sku) {
        beep({
          duration: 75,
          frequency: 200,
          volume: 0.03,
          type: 'triangle',
          onEnded: () => {
            setTimeout(() => {
              beep({
                duration: 75,
                frequency: 160,
                volume: 0.03,
                type: 'triangle',
              });
            }, 50);
          },
        });

        throw new Error('Product not found!');
      }

      beep({
        duration: 50,
        frequency: 960,
        volume: 0.03,
        type: 'triangle',
      });

      return addItemsToOrder({
        items: [{ ...(item || {}), sku: product.sku, quantity: 1 }],
      });
    },
    [addItemsToOrder, getProductByGtin]
  );

  const removeItemsFromOrder = useCallback(
    async ({ ids }: { ids: string[] }) => {
      const response = await removeItemsFromOrderMutation({
        orderId: order?.id || '',
        ids,
      });
      const updatedOrder = response?.removeItemsFromOrder?.order || null;

      setOrder(updatedOrder);
      return updatedOrder;
    },
    [order, removeItemsFromOrderMutation, setOrder]
  );

  const changeItemQuantityOnOrder = useCallback(
    async (input: ChangeItemQuantityOnOrderInput) => {
      const { item, quantity } = input || {};
      const [firstDetail] = item?.details || [];

      if (item.quantity === quantity) {
        return;
      }

      if (quantity > item.quantity) {
        return addItemsToOrder({
          items: [
            {
              adjustments: (item.adjustments || [])
                .filter((adjustment) => !adjustment?.outcome?.ruleId)
                .map((adjustment) => ({
                  id: adjustment.id,
                  description: adjustment.description,
                  amount: adjustment.amount,
                  isPercentage: adjustment.isPercentage,
                  isDiscount: adjustment.isDiscount,
                })),
              sku: item.sku,
              quantity: quantity - item.quantity,
              ...(firstDetail?.batchNumber
                ? { batchNumber: firstDetail.batchNumber }
                : {}),
              ...(firstDetail?.bestBefore
                ? { bestBefore: firstDetail.bestBefore }
                : {}),
              ...(firstDetail?.serialNumber
                ? { serialNumber: firstDetail.serialNumber }
                : {}),
              ...(firstDetail?.metaData
                ? { metaData: firstDetail.metaData }
                : {}),
            },
          ],
        });
      } else {
        const difference = item.quantity - quantity;
        const ids = [...(item.ids || [])].reverse().slice(0, difference);

        return removeItemsFromOrder({ ids });
      }
    },
    [addItemsToOrder, removeItemsFromOrder]
  );

  return {
    ...itemDiscount,
    addItemsToOrder,
    addItemToOrderByGtin,
    changeItemQuantityOnOrder,
    closeManageOrderItemDialog,
    closeManuallyAddProductDialog,
    exitedManageOrderItemDialog,
    getProductByGtin,
    openManageOrderItemDialog,
    openManuallyAddProductDialog,
    removeItemsFromOrder,
    selectedOrderItem,
    showManageOrderItemDialog,
    showManuallyAddProductDialog,
  };
};
