import {
  AppBar as AfAppBar,
  IconButton,
  Toolbar as AfToolbar,
  styled,
} from '@afosto/components';

export const AppBar = styled(AfAppBar)`
  background-color: ${(props) => props.theme.palette.darkGray[200]};
`;

export const Toolbar = styled(AfToolbar)`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: ${(props) => props.theme.spacing(2)};
  align-items: center;
  min-height: initial;
`;

export const ToolbarTitle = styled('div')`
  padding-left: ${(props) => props.theme.spacing(2.5)};
  right: ${(props) => props.theme.spacing(2.5)};
`;

export const ToolbarActions = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

export const ToolbarIconButton = styled(IconButton)`
  height: 100%;

  &.MuiIconButton-root.size-sizeLarge {
    border-left: 1px solid ${(props) => props.theme.palette.gray[800]};
    border-radius: 0;
    color: ${(props) => props.theme.palette.gray[200]}!important;
    padding-left: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};

    &.Mui-disabled {
      background-color: inherit;

      > .MuiSvgIcon-root {
        opacity: 40%;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.palette.gray[800]}!important;
      color: ${(props) => props.theme.palette.gray[200]}!important;
    }

    &:hover,
    &:active {
      border-radius: 0;
    }
  }
`;
