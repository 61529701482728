import { Avatar, Badge, Card, Typography } from '@afosto/components';
import { addQueryParamsToUrl } from '@afosto/utils';
import { Currency } from '../Currency';
import * as Styled from './ReceiptItem.styles';
import type { ReceiptItemProps } from './types';

export const ReceiptItem = (props: ReceiptItemProps) => {
  const { currency, item, onClick } = props;
  let imageUrl = item?.image;

  if (imageUrl?.startsWith('https://cdn.quicq.io')) {
    imageUrl = addQueryParamsToUrl(imageUrl, {
      h: 40,
      w: 40,
    });
  }

  return (
    <Card>
      <Styled.ActionArea
        onClick={onClick}
        sx={{ display: 'flex', px: 2.5, py: 2 }}
      >
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          badgeContent={item.quantity}
          overlap="circular"
          size="small"
        >
          <Avatar src={imageUrl} variant="rounded" />
        </Badge>
        <Styled.ProductInformation>
          <Typography variant="h6">{item.label || item.sku}</Typography>
          <Typography variant="h6" sx={{ justifySelf: 'flex-end' }}>
            <Currency currency={currency} value={item.total} />
          </Typography>
          {(item.adjustments || []).map((adjustment, idx) => (
            <>
              <Styled.AdjustmentContainer>
                <Typography
                  color="gray.700"
                  key={adjustment.description}
                  sx={idx !== 0 ? { columns: 'span 2' } : {}}
                >
                  {adjustment.description}
                  {adjustment.isPercentage ? ` (${adjustment.amount}%)` : ''}
                </Typography>
                <Typography color="gray.700">
                  <Currency
                    currency={currency}
                    value={
                      adjustment.isDiscount
                        ? adjustment.outcome?.amount * -1
                        : adjustment.outcome?.amount
                    }
                  />
                </Typography>
              </Styled.AdjustmentContainer>
              {idx === 0 && (
                <Typography
                  color="gray.700"
                  sx={{
                    justifySelf: 'flex-end',
                    textDecoration: 'line-through',
                  }}
                >
                  <Currency currency={currency} value={item?.subtotal} />
                </Typography>
              )}
            </>
          ))}
        </Styled.ProductInformation>
      </Styled.ActionArea>
    </Card>
  );
};
