import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  payment: {
    id: 'common.payment',
    defaultMessage: 'Payment',
    description: 'Common label for payment',
  },
  paid: {
    id: 'paymentStatus.paid',
    defaultMessage: 'Paid',
    description: 'Label for paid payment status',
  },
});
