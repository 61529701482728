import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { BottomNavigationAction, Paper } from '@afosto/components';
import { Home, Receipt, Tag, User } from '../../icons/solid';
import * as Styled from './BottomNavigation.styles';
import { translations } from './translations';

export const BottomNavigation = () => {
  const intl = useIntl();
  const { pathname } = useLocation();

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
      <Styled.BottomNavigationContainer showLabels value={pathname}>
        <BottomNavigationAction
          label={intl.formatMessage(translations.homePage)}
          value="/"
          icon={<Home />}
        />
        <BottomNavigationAction
          label={intl.formatMessage(translations.orders)}
          value="/orders"
          icon={<Receipt />}
        />
        <BottomNavigationAction
          label={intl.formatMessage(translations.products)}
          value="/products"
          icon={<Tag />}
        />
        <BottomNavigationAction
          label={intl.formatMessage(translations.contacts)}
          value="/customers"
          icon={<User />}
        />
        <BottomNavigationAction
          label={intl.formatMessage(translations.organisations)}
          value="/customers"
          icon={<User />}
        />
      </Styled.BottomNavigationContainer>
    </Paper>
  );
};
